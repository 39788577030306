@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	@media (prefers-reduced-motion: no-preference) {
		:root {
			@apply scroll-smooth;
		}
	}

	body {
		@apply m-0 font-sans text-base font-normal text-[#212529] bg-[#f5f5f5];
	}

	hr {
		@apply my-4 mx-0 text-inherit border-t border-solid opacity-[0.25];
	}
	/* margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25; */

	code {
		@apply font-[source-code-pro,Menlo,Monaco,Consolas,monospace];
	}

	.h6,
	.h5,
	.h4,
	.h3,
	.h2,
	.h1 {
		@apply mt-0 mb-2 font-medium leading-tight;
	}

	.h1 {
		@apply text-[calc(1.375rem+1.5vw)] xl:text-[2.5rem];
	}

	.h2 {
		@apply text-[calc(1.325rem+0.9vw)] xl:text-[2rem]
  /* font-size: calc(1.325rem + 0.9vw); */;
	}

	.h3 {
		@apply text-[calc(1.3rem+0.6vw)] xl:text-[1.75rem];
	}

	.h4 {
		@apply text-[calc(1.275rem+0.3vw)] xl:text-[1.5rem];
	}

	.h5 {
		@apply text-[1.25rem];
	}

	.h6 {
		@apply text-[1rem];
	}

	p {
		@apply mt-0 mb-4;
	}

	/* More */
	.bgGradientPrimary {
		@apply bg-gradient-to-l from-secondary to-[#3363f5];
	}

	.gradient-bg1 {
		background: #3264f5;
		background: -moz-linear-gradient(left, #3264f5 2%, #7004bc 82%);
		background: -webkit-linear-gradient(left, #3264f5 2%, #7004bc 82%);
		background: linear-gradient(to right, #3264f5 2%, #7004bc 82%);
	}
	.gradient-bg2 {
		background: linear-gradient(to right, #7004bc 2%, #3264f5 82%);
	}
	.bg-contact {
		background-image: linear-gradient(to bottom, #d8fefc, #fef0f5);
	}
	.font-tiro {
		font-family: 'Tiro Bangla', serif;
	}
	.font-anek {
		font-family: 'Anek Bangla', sans-serif;
	}
	.transition-w-1 {
		transition: width 1s;
	}
	@keyframes my-anime {
		0% {
			transform: translateY(-50px);
		}
		100% {
			transform: translateY(0px);
		}
	}
	.transition-nav {
		animation: my-anime 1000ms 1;
	}

	/* 
	blue: '#007bff',
	indigo: '#6610f2',
	purple: '#6f42c1',
	pink: '#e83e8c',
	red: '#dc3545',
	orange: '#fd7e14',
	yellow: '#ffc107',
	green: '#28a745',
	teal: '#20c997',
	cyan: '#17a2b8',
	white: '#fff',
	gray: '#6c757d',
	'gray-dark': '#343a40',
	primary: '#007bff',
	secondary: '#6c757d',
	success: '#28a745',
	info: '#17a2b8',
	warning: '#ffc107',
	danger: '#dc3545',
	light: '#f8f9fa',
	dark: '#343a40',
	*/
}
@layer base {
}
@layer utilities {
}
