@import url('https://fonts.maateen.me/charu-chandan-3d/font.css');
@import url('https://fonts.maateen.me/charu-chandan-hard-stroke/font.css');
@import url('https://fonts.maateen.me/kalpurush/font.css');
@import url('https://fonts.maateen.me/charukola-ultra-light/font.css');
@import url('https://fonts.maateen.me/apona-lohit/font.css');

.font-3d {
  font-family: 'CharuChandan3D', sans-serif;
}

.font-kalpurush {
  font-family: 'Kalpurush', sans-serif;
}
.font-hard {
font-family: 'CharuChandanHardStroke', sans-serif;
}
.font-charukola {
font-family: 'CharukolaUltraLight', sans-serif;
}
.font-apona {
font-family: 'AponaLohit', sans-serif;
}